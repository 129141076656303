import getDateString from '../../../utils/getDateString';
import { DocumentsGroup } from '../../Dialogs/DocumentListing/types';

const getLanguageKey = (source: string) => {
  if (source === 'jp') {
    return 'jp';
  }

  return 'unknown';
};

const getDocuments = (dataItem: any, source: string) => {
  const docs: DocumentsGroup[] = [];
  const document: DocumentsGroup = {
    id: 'documents',
    categoryTitle: 'Documents',
    documents: []
  };
  Object.keys(dataItem?.file_links || {}).forEach((type: string) => {
    const entries = dataItem?.file_links?.[type] ?? [];
    // Some of the sources not sending the category explicitly. So we are using the type which is mentioned in the key itself.
    const category = entries[0]?.category || type;
    if (entries.length > 1 || (entries.length === 1 && source === 'au')) {
      const categoryDocs = {
        id: type,
        categoryTitle: category,
        documents: entries.map((entry: any) => {
          // Try to get original language pdf.
          const otherLanguagesTitleLink = dataItem?.orig_file_links?.[type]?.find(
            (orig: any) => orig.s3_path === entry.s3_path
          )?.link;

          const otherLanguages = otherLanguagesTitleLink
            ? {
                [getLanguageKey(source)]: {
                  title_link: otherLanguagesTitleLink
                }
              }
            : null;

          return {
            title: entry.name,
            title_link: entry.link,
            category,
            ...(otherLanguagesTitleLink
              ? {
                  otherLanguages
                }
              : {})
          };
        })
      };

      docs.push(categoryDocs);
    } else if (entries.length === 1) {
      // Try to get original language pdf.
      const otherLanguagesTitleLink = dataItem?.orig_file_links?.[type]?.find(
        (orig: any) => orig.s3_path === entries[0].s3_path
      )?.link;

      const otherLanguages = otherLanguagesTitleLink
        ? {
            jp: {
              title_link: otherLanguagesTitleLink
            }
          }
        : null;
      document.documents.push({
        title: entries[0]?.name || category,
        title_link: entries[0].link,
        category,
        ...(otherLanguages
          ? {
              otherLanguages
            }
          : {})
      });
    }
  });
  if (document.documents.length > 0) {
    docs.unshift(document);
  }

  const trialDocument = {
    id: 2,
    categoryTitle: 'Trials in Scope',
    documents: dataItem?.trials_in_scope?.map((trial: any) => {
      return {
        title: trial.brief_title,
        metadata: [
          trial.nct_id,
          trial.phase,
          `Start: ${getDateString(trial.start_date)}`,
          `End: ${getDateString(trial.completion_date)}`
        ],
        actions: trial.docs.map((doc: any) => ({
          title: doc.doc_type,
          category: doc.doc_type,
          title_link: doc.s3_url
        }))
      };
    })
  };

  if (trialDocument.documents?.length > 0) {
    docs.push(trialDocument);
  }
  return docs;
};

export default getDocuments;
